import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
		constructor() {
			this.profile = '.js-profile';
			this.profile_lead = '.js-profile__lead';
			this.profile_img = '.js-profile__img';
			//this.profile_en = '.js-profile__en';
			this.profile_txt = '.js-profile__txt';
			this.init();
		}
	init() {
		const profile = document.querySelector(this.profile);
		const lead = document.querySelector(this.profile_lead);
		const img = document.querySelector(this.profile_img);
		//const en = document.querySelector(this.profile_en);
		const txt = document.querySelector(this.profile_txt);
		if (profile) {
			gsap.set(lead, {
				y: '10%',
				opacity: 0,
			})
			gsap.set(img, {
				y: '10%',
				opacity: 0,
			})
			//gsap.set(en, {
			//	x: '-40%',
			//	opacity: 0,
			//})
			gsap.set(txt, {
				y: '30px',
				opacity: 0,
			})
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: profile,
					start: 'top 33.33%',
					once: true,
					//markers: true,
				}
			});
			tl.to(lead, {
				y: 0,
				opacity: 1,
				duration: 1,
			})
			//.to(en, {
			//	opacity: 1,
			//	duration: 1.5,
			//})
			//.to(en, {
			//	x: '-50%',
			//	duration: 1,
			//	ease: 'power4.out',
			//}, '<')
			.to(img, {
				y: 0,
				opacity: 1,
				duration: 0.65,
			}, '-=1').to(txt, {
				y: 0,
				opacity: 1,
			});
		}
	}
}
