import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
        this.init();
        this.hero();
        this.btn();
    }

	init() {
		const fadeImgAry = gsap.utils.toArray('.js-grayscale');
		if (fadeImgAry) {
			fadeImgAry.forEach((fadeImg) => {
				ScrollTrigger.create({
					//markers: true,
					trigger: fadeImg,
					start: 'top 50%',
					end: 'bottom 50%',
					toggleClass: {
						targets: fadeImg,
						className: "is-active"
					},
					once: true
				});
			});
		}

		const fadeItems = gsap.utils.toArray('.js-fadeup');
		if (fadeItems) {
			fadeItems.forEach((fadeItem) => {
				gsap.set(fadeItem, {
					opacity: 0,
					y: '25%',
				});
				gsap.to(fadeItem, {
					y: 0,
					opacity: 1,
					duration: 0.75,
					ease: "power4.out",
					scrollTrigger: {
						trigger: fadeItem,
						start: 'top 75%',
						once: true,
						//markers: true,
					}
				})
			});
		}
	}
	hero() {
		const heroTxtWrap = document.querySelector('.js-hero-txt');
		const heroTxt = document.querySelectorAll('.js-hero-txt__in');
		if (heroTxtWrap) {
			window.addEventListener('load', () => {
				heroTxt.forEach((element,index) => {
					let second = (index * 0.5) + 0.25;
					gsap.set(element, {
						y: '23%',
					})
					gsap.to(element,{
						y: 0,
						opacity: 1,
						ease: "easeInOut",
						duration: 0.65,
						delay: second,
					})
				});
			});
		}
	}
	btn() {
		const btnAry = document.querySelectorAll('.c-btn01');
		if (btnAry) {
			btnAry.forEach((btn) => {
				let link = btn.querySelector('a');
				gsap.set(link, {
					opacity: 0,
					y: '25%',
				});
				gsap.to(link, {
					y: 0,
					opacity: 1,
					duration: 0.75,
					ease: "power4.out",
					scrollTrigger: {
						trigger: link,
						start: 'top 80%',
						once: true,
						toggleClass: {
							targets: link,
							className: "is-active"
						},
					}
				});
			});
		}
	}
}